import { template as template_3c16229326e54d2c9d342152a9d4121b } from "@ember/template-compiler";
const FKText = template_3c16229326e54d2c9d342152a9d4121b(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
